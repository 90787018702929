<template>
  <div class="menu-list">
    <el-menu
      :class="['el-menu-vertical',{'w260':lang=='en'},{'w280':lang=='de'}]"
      :default-active="activeMenu"
      :default-openeds="menuOpeneds"
      @open="handleOpen"
      mode="vertical">
      <template v-for="(item,key) in menuList" >
        <!-- {{item.active}} -->
        <!-- 多级菜单嵌套展示 -->
        <el-submenu :index="String(item.active)" v-if="item.children.length" >
          <!-- 一级标题 -->
          <template #title>
            <i :class="item.icon" class="m-first-icon"></i>
            <span>{{item.title}}</span>
          </template>
          <!-- 二级菜单 -->
          <template v-if="item.children.length" v-for="(subItem,subKey) in item.children">
            <!-- 存在三级菜单 -->
            <el-submenu class="m-third-menu" :index="subItem.active" v-if="subItem.children.length">
              <!-- 二级标题 -->
              <template #title>
                <span>{{subItem.title}}</span>
              </template>
              <!-- 三级菜单项目 -->
              <template v-if="subItem.children.length" >
                <el-menu-item :disabled="disabledMenuItem(thirdItem)" class="m-third" :index="thirdItem.active" v-for="(thirdItem,thirdKey) in subItem.children" :key="thirdItem.active" @click="toLink(thirdItem.router)" v-setPermission :permissionInfo="thirdItem.permission">
                  {{thirdItem.title}}
                  <span class="menu_li_span" v-if="thirdItem?.notify > 0">{{thirdItem.notify>99?'99+':thirdItem.notify}}</span>
                </el-menu-item>
              </template>
            </el-submenu>
            <!-- only 二级菜单 begin -->
            <template v-else>
              <el-menu-item class="m-second" :index="subItem.active" @click="toLink(subItem.router)"  v-setPermission :permissionInfo="subItem.permission">
               {{subItem.title}}
               <span class="menu_li_span" v-if="subItem?.notify > 0">{{subItem.notify>99?'99+':subItem.notify}}</span>
              </el-menu-item>
            </template>
            <!-- only 二级菜单 end  -->
          </template>
          <!-- 二级菜单end -->
        </el-submenu>
        <!-- 单个一级菜单展示 -->
        <template v-else>
            <el-menu-item :index="String(item.active)" @click="toLink(item.router)" v-setPermission :permissionInfo="item.permission">
              <template #title>
                <i :class="item.icon" class="m-first-icon"></i>
                <span>{{item.title}}</span>
              </template>
            </el-menu-item>
            </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
import defaultMenu from '@/assets/js/menu.js'
import {
  requestUrl,
  ajax,
  checkLogin,
  getLoginInfo,
  checkIdType,
} from "@/assets/utils/network";

export default {
  data() {
    return {
      product:'cn',
      lang: 'zh',
      drawerVisible: false, // 控制抽屉显示
      activeMenu: '0',// 默认激活的菜单项
      menuOpeneds: [],
      menuList:[],
      creatFlag: false,
    };
  },
  props: ["isActive"],
  watch: {
    isActive: {
      handler(newValue, oldValue) {
        if(newValue) {
        this.activeMenu = this.isActive;
        }
      },
      immediate: true
    },
    menuList: {
      handler(newValue, oldValue) {
        if(newValue.length) {
          this.$nextTick(() => {
            this.initMenuSet();
            this.openAllThirdMenu(this.isActive);
          })
        }
      },
      immediate: true,
      deep: true,
    }
  },
  methods: {
    toLink(router) {
      // window.location.href = router
      let type = '_self';
      if(['analyzeBI.html', 'chargeMonitor.html'].includes(router)) {
        type = '_blank';
      }
      window.open(router, type);
    },
    disabledMenuItem(menuItem) {
      return menuItem.router == 'createDepot.html' && this.creatFlag;
    },
    //菜单隐藏
    initMenuSet(){
      // 所有三级菜单在permissionsV2中没有权限时，删掉直系二级菜单，所有二级菜单在permissionsV2中没有权限时，删掉直系一级菜单
      const hideList = Array.prototype.slice.call(document.querySelectorAll('.el-submenu ul'))
      for(let index = hideList.length-1; index > 0; index--) {
        const ele = hideList[index];
        const childList = Array.prototype.slice.call(ele.children);
        let has = childList.every((item) => item.style.cssText.search('display: none') > 0)
        if (has) {
          ele.parentNode.parentNode.removeChild(ele.parentNode);
        }
      }
      this.menuSelect();
    },
    // 激活菜单样式加粗
    menuSelect() {
      // 查找所有具有 .active 类名的元素
      const activeElements = document.querySelectorAll('.el-menu .is-active');
      // 遍历每一个 .active 元素
      activeElements.forEach(activeElement => {
        // 遍历 activeElement 的子元素
        Array.from(activeElement.children).forEach(child => {
          if (child.classList.contains('el-submenu__title')) {
            // 给找到的 .sub_title 元素添加样式
            child.classList.add('active_el_submenu__title');
          }
        });
      });
    },
    // 展开当前菜单项中所有的3级菜单
    openAllThirdMenu(activeMenuId) {
      let parentId = activeMenuId.split('-')[0];
      const ids = [];
      const temp = this.menuList.filter((item) => item.active == parentId);
      if(!temp.length) return ids;
      temp[0].children.forEach((item) => {
        if (item.children.length) {
          ids.push(item.active);
        }
      })
      this.menuOpeneds = [parentId, ...ids];
    },
    handleOpen(key, keyPath) {
      // 二级菜单手动点开时不用展开所有三级菜单
      if(keyPath.length > 1) return;
      this.openAllThirdMenu(key);
    },
    ajaxFn(url) {
      return new Promise((resolve, reject) => {
        ajax({
          type: "GET",
          url:url,
          dataType: "json",
          success: (response) => {
            const result = JSON.parse(response);
            resolve(result);
          },
        });
      })
    },
    async menuPrune() {
      const permissions = checkLogin("permissionsV2");
      const roles = checkLogin("roles")||[];
      const noPermissions = [];   //无权限项
      const permissionsArr = [];   //有权限项
      for(let i in permissions){
        permissionsArr.push(i)
        if(permissions[i][1]==0||permissions[i][1]==0){
          noPermissions.push(i)
        }
      }
      if( permissions.siteInfo?.[0] === 2 && permissions.siteInfo?.[1] === 1 ) {
        this.creatFlag = true
      }
      // 遍历查询应该删除的子项
      const removeActive = (arr, targetActive) => arr.filter(item => {
        // 递归处理子项
        item.children && (item.children = removeActive(item.children, targetActive));
        // 过滤掉当前项和其子项
        // return item.active != targetActive;
        return !targetActives.includes(item.active)
      });
      //需要删除的项
      let targetActives = [];
      // 国内环境
      if(window.product_area=='cn'){
        // 国内屏蔽场站地图
        targetActives.push('13');
        //加盟商管理员权限
        if (checkIdType("dealerLevel2")) {
          targetActives.push('2-4','8-1-5','8-2-4','8-3-4','8-4-5','3-1','9','6-1','6-2-1','6-2-0','6-6');
          // 营销中心
          targetActives.push('7-1-2','7-1-3','7-2-1','7-2-4','7-2-3','7-6','7-3','7-4-0','7-4-1','7-5-0','7-5-1','7-5-2',);
        }
        // 站点管理员权限
        if(checkIdType("site")){
          targetActives.push('1','2-1-0','4-2-0','6');
          // 营销中心
          targetActives.push('7-1-2','7-1-3','7-2-1','7-6','7-3','7-4-0','7-4-1','7-5-0','7-5-1','7-5-2');
          //站点管理员屏蔽掉用户管理
          if(!targetActives.includes('6-1')) targetActives.push('6-1');
        }
        // 特殊处理
        if(permissions.biInfo?.[0] === 0 && permissions.biInfo?.[1] === 0) {
          targetActives.push('1');
        }
      }
      // 国外欧洲环境
      if(window.product_area=='eu'){
        targetActives.push('1','2-2-1','3-1','3-2','7','9','10','5');
        // 用户中心
        targetActives.push('6-1-0','6-1-2','6-2-0','6-1-3','6-1-1','6-6','6-2-1');
        // 维保中心
        targetActives.push('4-5-1','4-3','4-0','4-5-3');
        // 数据中心
        targetActives.push('8-0','8-5','8-1-2','8-1-4','8-2-3','8-3-3','8-4-2','8-4-3','8-4-4');
        // 系统中心
        targetActives.push('12-2-0','12-2-1','12-2-2','12-2-3');
      }
      // 财务中心-财务设置 #10387：财务设置合并后，多个不同权限的控制
      if(permissions.dealerInfo?.[1] === 0 && permissions.outsideOwnerInfo?.[1] === 0 && permissions.userGroupInfo?.[1] === 0){
        targetActives.push('9-5')
      }
      // 根据是否有能源站点屏蔽能源中心
      const siteInfo = await this.ajaxFn(`${requestUrl}/site?brief=true&orderColumn=title&order=asc&energySite=true`);
      if(!siteInfo.result?.length) {
        targetActives.push('11')
      }
      this.menuList = removeActive(defaultMenu, targetActives);
      this.getCount();
    },
    getCount() {
      // 财务中心
      const finance = this.menuList.filter(it=>it.active==='9');
      if(finance.length){
        //查询个人发票申请数量
        this.ajaxFn(`${requestUrl}/wechat/invoice/stats?userType=wechat`).then(res => {
          let count = res.result?.processingNum || 0;
          const temp = finance[0].children.filter(it=>it.active==='9-1');
          this.$set(temp[0],'notify',count);
        })
        //查询团体发票申请数量
        this.ajaxFn(`${requestUrl}/wechat/invoice/stats?userType=userGroup`).then(res => {
          let count = res.result?.processingNum || 0;
          const temp = finance[0].children.filter(it=>it.active==='9-2');
          this.$set(temp[0],'notify',count);
        })
        //查询退款申请数量
        this.ajaxFn(`${requestUrl}/wechatrefund?from=0&length=1&returnCount=true&status=unfinished`).then(res => {
          let count = res.totalCount || 0;
          const temp = finance[0].children.filter(it=>it.active==='9-3');
          this.$set(temp[0],'notify',count);
        })
        //自助缴费未读数--平台账单
        this.ajaxFn(`${requestUrl}/dealer/bill/stats`).then(res => {
          let count = res?.unpaidCount || 0;
          if(finance.length){
            const temp = finance[0].children.filter(it=>it.active==='9-6');
            if(temp.length){
              const thirdTemp = temp[0].children.filter(it=>it.active==='9-6-1');
              this.$set(thirdTemp[0],'notify',count);
            }
          }
        })
      }
      // 用户中心
      //统计微信公众号未读消息数
      const user = this.menuList.filter(it=>it.active==='6');
      if(user.length){
        this.ajaxFn(`${requestUrl}/wechatOAMsg/unread/count`).then(res => {
          let count = res.totalCount || 0;
          const temp = user[0].children.filter(it=>it.active==='6-6');
          this.$set(temp[0],'notify',count);
        })
      }
    },
  },
  mounted() {
    this.product = window.product_area || 'cn';
    this.lang = window.localStorage.lang;
    this.menuPrune();
  }
}
</script>


<style lang="scss">
.el-menu-vertical {
  width: 200px; /* 使菜单占满整个抽屉 */
  &.w260 {
    width: 260px;
  }
  &.w280{
    width:280px;
  }
  .el-menu-item, .el-submenu__title  {
    color: #8087a0;
    height: 50px;
    line-height: 50px;
  }
  // 一级菜单icon
  .m-first-icon {
    margin-right: 5px;
    font-size: 20px!important;
    margin-top: -2.5px;
    display: inline-block;
    color: #8087a0;
  }
  // 二级菜单icon
  .m-second{
    position: relative;
    &::before {
      content: "";
      display: inline-block;
      width: 6px;
      height: 2px;
      background:rgb(209, 213, 219);
      position: absolute;
      top: 50%;
      left: 26px;
      transform: translateY(-50%);
    }
    &.is-active::before {
      background: #5278E2;
    }
  }
  // 三级菜单icon
  .m-third{
    position: relative;
    &::before {
      content: "";
      display: inline-block;
      width: 4px;
      height: 4px;
      border-radius: 100%;
      background:rgb(209, 213, 219);
      position: absolute;
      top: 50%;
      left: 48px;
      transform: translateY(-50%);
    }
    &.is-active::before {
      background: #5278E2;
    }
  }
  .m-third-menu {
    .el-submenu__title {
      @extend .m-second;
    }
    .active_el_submenu__title {
      &::before {
        background: #5278E2;
      }
    }
  }
  .menu_li_span{
    padding: 1px 6px;
    border-radius: 10px;
    background: #ed3512;
    color: #fff;
    vertical-align: top;
    font-size: 12px;
    margin-left: 4px;
  }
  .active_el_submenu__title{
    color: #5278E2;
    font-weight: 800;
    i{
      color: #5278E2;
    }
  }
  .el-menu-item.is-active{
    font-weight: 800;
  }
}

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all 0.3s ease;
}

.slide-fade-enter, .slide-fade-leave-to /* .slide-fade-leave-active in <2.1.8 */ {
  // width: 0;
  opacity: 0;
  transform: translateX(-100%);
}
</style>